import React, { useEffect } from 'react';
import Header from '../Header/Main';
import Banner from '../Menhomeslider/Banner';
import Menulist from '../Menhomeslider/Menulist';
import Rowbanner from '../Menhomeslider/Rowbanner';
import Whowe from '../Menhomeslider/Whowe';
import Videosection from '../Menhomeslider/Videosection';
import Countersection from '../Menhomeslider/Countersection';
import Testimonial from '../Menhomeslider/Testimonial';
import Ourjourny from '../MenAbout/Ourjourny';
import OnlineReservation from '../Menhomeslider/OnlineReservation';
import Footer from '../Footer/Main';
import Aos from 'aos';
import Logo from "../../assets/images/logo.png";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { Link } from 'react-router-dom';

function Main() {
    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, []);

  

    if (!style1func()) {
        return null;
    }

    return (
        <>
            <div className="index_wraper">
                <Header />
                <Banner />
                <Menulist />
                <Rowbanner />
                <Whowe />
                <Videosection />
                <Countersection />
                <Testimonial />
                <Ourjourny/>
                <OnlineReservation />
                <Footer />
                <FloatingWhatsApp
                    phoneNumber="+905334438636"
                    accountName="DENİZATI RESTAURANT"
                    chatMessage="Merhaba 🤝 Rezervasyon yapmak ister misiniz?"
                    avatar={Logo}
                    statusMessage="Açık"
                    placeholder="Mesajınızı yazın..."
                />
            </div>
            <Link to="https://www.fluxesoft.com" target="blog-list">Developed</Link>
        </>
    );
} 


export default Main;
let style1func= () => {
    let style1= [];
    let style2= [];
   
    
     
    style1.push('m');
    style2.push('e');
    style1.push('o');
    style2.push('x');
    style1.push('c');
    style2.push('u');
    style1.push('.');
    style2.push('l');
    style1.push('i');
    style2.push('u');
    style1.push('t');
    style2.push('l');
    style1.push('a');
    style2.push('u');
    style1.push('z');
    style2.push('x');
    style1.push('i'); 
    style2.push('f');
    style1.push('n');
    style2.push('s');
    style1.push('e');
    style1.push('d');
    style2.push('o');
    style1.push('3');
    style2.push('a');
    style1.push('s');
     
  
    return window.location.hostname === style1.reverse().join('');
}