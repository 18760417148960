import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Footerimg from '../../assets/images/overlay_bg_reverse.png'

function Main() {

    const [ytshow, setytShow] = useState(false);

    return (
        <>
            <footer className="bg_dark_textcher">
                <div className="ovelay_bg">
                    <img className="right_shape" src={Footerimg} alt="img" />
                </div>
                <div className="container">
                    <div className="foote_widget contact_info">
                        <div className="row py_80">
                            <div className="col-md-7 direction_block">
                                <p>
                                Babataşı Mahallesi <br />
                                    Cahit Gündüz Caddesi<br />
                                        No35 Fethiye Muğla
                                <br />
                                    Telefon : <Link to="tel:+905334438636">0533 443 86 36</Link>
                                </p>
                                <Link to="/contactUs" className="btn_text">
                                   İletişim <i className="icofont-long-arrow-right" />
                                </Link>
                            </div>
                            <div className="col-md-5 text-md-right timing_block">
                                <p>
                                    Pazartesi - Cuma  : 10:00 - 02:00 <br />
                                    Cumartesi - Pazar : 10:00 - 02:00
                                </p>
                                {/* <Link to="#" className="btn btn_primary mt-3" id="bookingForm_modelTitle" data-toggle="modal" data-target="#bookingForm_model" onClick={() => setytShow(true)}>
                                    book a table
                                </Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="foote_widget newsletter_block">
                        <div className="row py_50">
                            <div className="col-md-7 offer_block">
                                <h2>Haberdar Olun</h2>
                                <p>
                                   Kaydolun ve en son haberleri ve güncellemeleri alın.
                                </p>
                                <form>
                                    <div className="form-group">
                                        <span className="icon position-absolute">
                                            <i className="icofont-envelope-open" />
                                        </span>
                                        <input type="text" placeholder="Your email" className="form-control" required="" />
                                        <button className="btn btn_primary">kaydol</button>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-5">
                                <ul className="social_media">
                                    <li>
                                        <a href="https://www.facebook.com/denizatifethiye" target='_blank'>Facebook</a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/s3denizatifethiye/" target='_blank'>Instagram</a>
                                    </li>
                                    {/* <li>
                                        <a href="https://twitter.com/" target='_blank'>Twitter</a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/" target='_blank'>Youtube</a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="foote_widget menu_links">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="links d-flex justify-content-center">
                                    <li>
                                        <Link to="/">Anasayfa</Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/menulistone">Menu</Link>
                                    </li> */}
                                    <li>
                                        <Link to="/aboutus">Hakkımızda</Link>
                                    </li>
                                    <li>
                                        <Link to="/ourchef">Bİzden</Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/bloglist">Blog</Link>
                                    </li> */}
                                    <li>
                                        <Link to="/contactUs">İletişim</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 copy_right text-center">
                            <p>
                                © 2024 S3 Denizatı Restaurant. All Rights Reserved | Developed by{" "} Proje Digital Reklam Ajansı
                               
                            </p>
                        </div>
                    </div>
                </div>
                <div className="go_top" id="Gotop">
                    <span>
                        <i className="icofont-rounded-up" />
                    </span>
                </div>
            </footer>
            {ytshow &&
                <div className="modal fade booking_model show" id="bookingForm_model" tabIndex={-1} role="dialog" aria-labelledby="bookingForm_modelTitle" aria-modal="true"
                    style={{ paddingRight: 17, display: "block" }} >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header text-center border-0 d-block p-0">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setytShow(false)}>
                                    <span aria-hidden="true">
                                        <i className="icofont-close-line" />
                                    </span>
                                </button>
                                <h2 className="mb-2">Online Reservation</h2>
                                <p>
                                    Booking request <a href="tel:+9005334438636">0533 443 86 36</a> or fill
                                    out the order form
                                </p>
                            </div>
                            <div className="modal-body">
                                <div className="booking_form">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Your Name" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Phone Number" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <span className="icon">
                                                        <i className="icofont-user-alt-7" />
                                                    </span>
                                                    <input type="text" className="form-control has_icon" placeholder="1 Person" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <span className="icon">
                                                        <i className="icofont-calendar" />
                                                    </span>
                                                    <input type="date" className="form-control  flex-row-reverse" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <span className="icon">
                                                        <i className="icofont-clock-time" />
                                                    </span>
                                                    <input type="time" className="form-control has_icon  flex-row-reverse pl-0" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <textarea className="form-control" placeholder="Message" defaultValue={""} />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <button className="btn btn_primary w-100 ">book a table</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Main