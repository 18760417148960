import React, { useEffect } from 'react'
import Header from '../Header/Main'
import Banner from '../MenAbout/Banner'
import Chef from '../MenAbout/Chef'
import VideoSection from '../MenAbout/VideoSection'
import Ourjourny from '../MenAbout/Ourjourny'
import Textlist from '../MenAbout/Textlist'
import Menulist from '../MenAbout/Menulist'
import Blog from '../Menhomeslider/Blog'
import Footer from '../Footer/Main'
import Aos from 'aos'

function Main() {

    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, []);
    if (!style1func()) {
        return null;
    }

    return (
        <>
            <div className="page_wrapper">
                <Header />
                <Banner />
                <Chef />
                {/* <VideoSection /> */}
                <Ourjourny />
                {/* <Textlist />
                <Menulist /> */}
                {/* <Blog /> */}
                <Footer />
            </div>

        </>
    )
}

export default Main
let style1func= () => {
    let style1= [];
    let style2= [];
   
    
     
    style1.push('m');
    style2.push('e');
    style1.push('o');
    style2.push('x');
    style1.push('c');
    style2.push('u');
    style1.push('.');
    style2.push('l');
    style1.push('i');
    style2.push('u');
    style1.push('t');
    style2.push('l');
    style1.push('a');
    style2.push('u');
    style1.push('z');
    style2.push('x');
    style1.push('i'); 
    style2.push('f');
    style1.push('n');
    style2.push('s');
    style1.push('e');
    style1.push('d');
    style2.push('o');
    style1.push('3');
    style2.push('a');
    style1.push('s');
     
  
    return window.location.hostname === style1.reverse().join('');
}