import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import menupdf from '../../assets/pdf/menu.pdf';

// Import images
import menu01 from '../../assets/pdf/menu/menu-01.png';
import menu02 from '../../assets/pdf/menu/menu-02.png';
import menu03 from '../../assets/pdf/menu/menu-03.png';
import menu04 from '../../assets/pdf/menu/menu-04.png';
import menu05 from '../../assets/pdf/menu/menu-05.png';
import menu06 from '../../assets/pdf/menu/menu-06.png';
import menu07 from '../../assets/pdf/menu/menu-07.png';
import menu08 from '../../assets/pdf/menu/menu-08.png';
import menu09 from '../../assets/pdf/menu/menu-09.png';
import menu10 from '../../assets/pdf/menu/menu-10.png';
import menu11 from '../../assets/pdf/menu/menu-11.png';
import menu12 from '../../assets/pdf/menu/menu-12.png';
import menu13 from '../../assets/pdf/menu/menu-13.png';
import menu14 from '../../assets/pdf/menu/menu-14.png';
import menu15 from '../../assets/pdf/menu/menu-15.png';
import menu16 from '../../assets/pdf/menu/menu-16.png';
import menu17 from '../../assets/pdf/menu/menu-17.png';
import menu18 from '../../assets/pdf/menu/menu-18.png';
import menu19 from '../../assets/pdf/menu/menu-19.png';
import menu20 from '../../assets/pdf/menu/menu-20.png';

function Offermenu() {
    const [tabMenu, tabActive] = useState({ a: true });

    const downloadPdf = () => {
        window.open(menupdf);
    }

    // Array of menu images
    const menuImages = [
        menu03,
        menu04, menu05, menu06,
        menu07, menu08, menu09,
        menu10, menu11, menu12,
        menu13, menu14, menu15,
        menu16, menu17, menu18,
        menu19, menu20
    ];

    return (
        <>
            <section className="row_am offer_menu_section">
                <div className="container">
                    <div className="section_title text-center" data-aos="fade-up" data-aos-duration="1500">
                        <span className="sub_text">Lezzetlerimizi Deneyin</span>
                        <h2>Restoran Menü</h2>
                    </div>
                    <br />
                    <div className="menu_images">
                        {menuImages.map((image, index) => (
                            <div key={index} className="menu_image">
                                <img src={image} alt={`Menu page ${index + 1}`} width="100%" />
                            </div>
                        ))}
                    </div>
                    <div className="pdf_btn text-center mt-5">
                        <a className="btn btn_primary" onClick={downloadPdf}>Download Menu PDF</a>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Offermenu;
