import React from 'react'
import Fry from '../../assets/images/french_fry.png'
import Icon1 from '../../assets/images/outj_icon1.png'
import Icon2 from '../../assets/images/outj_icon2.png'
import Icon3 from '../../assets/images/outj_icon3.png'

function Ourjourny() {
    return (
        <>
            <section className="row_am our_journey bg_yellow_textcher">
                <div className="side_shape position-absolute" data-aos="fade-in" data-aos-duration={1500} >
                    <img src={Fry} alt="img" />
                </div>
                <div className="container">
                    <div className="row_am">
                        <div className="section_title" data-aos="fade-up" data-aos-duration={1500} >
                            <span className="sub_text">DENİZATI RESTAURANT FETHİYE:</span>
                            <h2>
                             LEZZETLİ YEMEKLER VE ÖZEL ANLARIN ADRESİ
                            </h2><br />
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="joueney_box" data-aos="fade-up" data-aos-duration={1500} >
                                    <div className="icon">
                                        <img src={Icon1} alt="img" />
                                    </div>
                                    <h3>ADRES</h3>
                                    <p>
                                    Babataşı Mahallesi                                     <br />
                                    Cahit Gündüz Caddesi                                    <br />
                                    No:35 Fethiye Muğla

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="joueney_box" data-aos="fade-up" data-aos-duration={1500} >
                                    <div className="icon">
                                        <img src={Icon2} alt="img" />
                                    </div>
                                    <h3>LEZZETLİ YEMEKLER

                                    </h3>
                                    <p>
                                    Akdeniz'in zengin mutfak <br />kültürü, taptaze malzemeler<br /> ve modern dokunuşlar
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="joueney_box" data-aos="fade-up" data-aos-duration={1500} >
                                    <div className="icon">
                                        <img src={Icon3} alt="img" />
                                    </div>
                                    <h3>HIZLI SERVİS</h3>
                                    <p>
                                    Hızlı ve güler yüzlü servis<br /> ekibimizle unutulmaz <br />anlar yaşayın
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Ourjourny